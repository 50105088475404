<template>
  <div class="container position-sticky z-index-sticky top-0">
    <navbar
      v-show="false"
      btn-background="bg-gradient-warning"
      :dark-mode="true"
      is-blur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
    />
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/error-500.jpg');
      "
    >
      <span class="mask bg-gradient-warning opacity-4"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-7 mx-auto text-center">
            <h1
              class="display-1 text-bolder text-white fadeIn1 fadeInBottom mt-5"
            >
              Remove User
            </h1>
            <h2 class="fadeIn3 fadeInBottom mt-3 text-white">processing...</h2>
            <p class="lead fadeIn2 fadeInBottom text-white">
              please wait for remove auth and redirection...
            </p>
            <argon-button
              v-show="false"
              color="warning"
              variant="gradient"
              class="mt-4 fadeIn2 fadeInBottom"
              >Go to Homepage</argon-button
            >
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer v-show="false" />
</template>
<script>
import axios from "axios";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapMutations } from "vuex";
export default {
  name: "SignupBasic",
  components: {
    Navbar,
    AppFooter,
    ArgonButton,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    this.clearSession();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    async clearSession() {
      try {
        const response = await axios.get("/api/v1/logout", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        console.log(response.data.message);
        this.clearValueSession();
        setTimeout(() => {
          //this.$router.push("/login");
          window.location.href = this.$hostname + "/login";
        }, 2000);
      } catch (error) {
        console.log(error);
        // redirect to login
        setTimeout(() => {
          this.clearValueSession();
          //this.$router.push("/login");
          window.location.href = this.$hostname + "/login";
        }, 2000);
      }
    },
    clearValueSession() {
      localStorage.setItem("token", "");
      localStorage.setItem("refresh_token", "");
      localStorage.setItem("google_token", "");
      localStorage.setItem("auth", "");
    },
  },
};
</script>
